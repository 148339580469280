/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  border-style: solid;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
